import React, { useContext } from 'react'

import Typist from '@components/common/typist'
import Link from '@components/common/link'

import StrategyContext from '@store/our-strategy/strategy-context'

const ChapterHeader = ({ title, textShadow, quote }) => {
	return (
		<div className={'px-5 flow flex flex-col justify-center items-center'}>
			<h1
				style={{ textShadow: textShadow ? '2px 2px 15px rgba(0,0,0,0.6)' : 'none' }}
				className={'text-white text-center md:text-6xl lg:text-7xl xl:text-8xl sm:px-32'}
			>
				{title}
			</h1>
			<h2 className={'z-50 px-3 py-2 inline-block mx-auto text-white bg-burgundy text-center text-xl sm:text-5xl'} style={{ marginTop: '2.8rem' }}>
				<Typist texts={quote} />
			</h2>
		</div>
	)
}

const InlineImage = ({ caption, fileName, wide }) => {
	return (
		<div className={`py-12 ${wide ? 'sm:mx-0 md:-mx-52 lg:-mx-84 xl:-mx-96' : 'sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80 min-w-full'}`}>
			<img className={'block w-full'} src={`/images/reports/our-strategy/${fileName}`} />
			{caption && (
				<p style={{ background: '#f2f2f2', color: '#63666a' }} className={'text-gray-600 p-4'}>
					{caption}
				</p>
			)}
		</div>
	)
}

const InlineTypist = ({ text, textAlign }) => {
	return (
		<div className={text.length > 20 ? 'sm:-mx-20' : ''}>
			<h1 className={`z-50 w-full inline-block mx-auto text-burgundy text-center text-2xl sm:text-5xl mb-8 ${textAlign || 'text-center'}`}>
				<Typist texts={text} />
			</h1>
		</div>
	)
}

const JumpChapterLink = ({ chapter, children }) => {
	const { jumpChapter } = useContext(StrategyContext)

	return (
		<span className={'font-bold text-burgundy cursor-pointer'} onClick={() => jumpChapter(chapter)}>
			{children}
		</span>
	)
}

const CHAPTERS = [
	// Section 1
	{
		title: 'Welcome',
		id: 'welcome',
		backgroundImage: 'chapter-1-header',
		className: 'bg-gray-300',
		htmlContent: <ChapterHeader title={'Welcome'} quote={['Building the newsroom of the future.']} />,
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28 mb-32 md:mb-56'}>
				<InlineTypist text={['Building the newsroom of the future.']} key={`${Math.random()}-typist-1`} />
				<p>
					The current journalistic model is broken: audiences are demanding that the hierarchical, elite-led system of news gathering and presentation behind
					journalism be dismantled in favour of a more inclusive and holistic approach based on more equitable access to information and more nuanced and
					multi-perspective narratives.
				</p>
				<p>
					There is a growing demand for decolonised, meaningful participatory media spaces – with different power dynamics - and an increasing expectation of
					journalism as a constructive force for good, rather than simply an “objective” chronicler of the news.
				</p>
				<p>
					The business model for media is also broken, with many media going bankrupt during the pandemic - despite their information being more valuable than
					ever - because of a dependence on advertisers.
				</p>
				<p>Finally, exploitative and extractive practices have long been commonplace in media and other businesses.</p>
				<InlineImage
					fileName={'philip.jpeg'}
					caption={'The New Humanitarian’s Philip Kleinfeld reporting in the Democratic Republic of Congo. (Philip Kleinfeld/TNH)'}
				/>
				<p>We think there is a better way. We want to build something different.</p>
				<p>
					Thus, we will continue to produce journalism that can compete with the world's most respected publications, but we will chart our own path in defining
					what a progressive transformative newsroom means to us.
				</p>
				<p>Our strategy sees us creating that transformation in the following ways:</p>
				<ul>
					<li className={'mb-6'}>
						<p>challenging the power structures within journalism, especially how stories are told and who sets the narrative;</p>
					</li>
					<li className={'mb-6'}>
						<p>intentionally pursuing impact as central to the production of our journalism; </p>
					</li>
					<li className={'mb-6'}>
						<p>bolstering our ability to pursue the most challenging stories;</p>
					</li>
					<li className={'mb-6'}>
						<p>developing a viable model of mission-driven journalism on the international stage</p>
					</li>
					<li className={'mb-6'}>
						<p>building a progressive newsroom culture.</p>
					</li>
				</ul>
				<p>Members of The New Humanitarian are helping us build the newsroom of the future. Join them by becoming a member today.</p>

				<p className={'flex justify-center items-center pt-4'}>
					<Link href={'https://www.thenewhumanitarian.org/membership'} passHref>
						<a className={'bg-burgundy text-white hover:text-white px-5 py-3 rounded-xs'}>This work is important. I’ll join you.</a>
					</Link>
				</p>
			</div>
		),
	},
	// Section 2
	{
		title: 'Mission & theory of change',
		id: 'mission-and-theory-of-change',
		backgroundImage: 'chapter-2-header',
		className: 'bg-gray-300',
		htmlContent: <ChapterHeader title={'Mission and theory of change'} quote={['Journalism as a force for good.']} />,
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28 mb-32 md:mb-56'}>
				<InlineTypist text={['Journalism as a force for good.']} key={`${Math.random()}-typist-2`} />
				<h2>Our mission</h2>
				<p>
					Our strategy begins with our mission. The New Humanitarian is an independent, non-profit newsroom that believes in journalism as a force for good. We
					produce fact-based journalism from the heart of conflicts and disasters to build understanding of how to improve the lives of the millions of people
					affected by humanitarian crises around the world.
				</p>
				<h2>Our theory of change</h2>
				<p>
					Our theory of change describes the change we want to see in the world by delivering on our mission. We contribute to more effective, accountable and
					inclusive ways to improve the lives of people affected by crises, by:
				</p>
				<ul>
					<li className={'mb-6'}>
						<p>Informing policy-makers and practitioners working in humanitarian response or related fields of needs on the ground</p>
					</li>
					<li className={'mb-6'}>
						<p>
							Providing accountability and transparency over governments, aid agencies, and others meant to help - or responsible for the suffering of - people
							in need
						</p>
					</li>
					<li className={'mb-6'}>
						<p>Raising awareness of forgotten crises among a wider public</p>
					</li>
					<li className={'mb-6'}>
						<p>Providing a platform for conversation and discussion with and among affected and marginalised people about how to improve their own lives</p>
					</li>
				</ul>
				<p>
					Five <strong>strategic priorities</strong> will help us deliver on this mission and theory of change in the years to come, in line with our vision to
					become a transformative newsroom for our times.
				</p>
				<ol className={'list-decimal'}>
					<li className={'mb-6'}>
						<p>
							<strong>Decolonising our journalism</strong> - by being more inclusive of and guided by the communities we serve so that we better represent the
							issues that matter to them. <JumpChapterLink chapter={'decolonise-journalism'}>Read more</JumpChapterLink>
						</p>
					</li>

					<li className={'mb-6'}>
						<p>
							<strong>Centring impact in our work</strong> - by pro-actively mapping and reaching out to audiences that can be served by a given story - so that
							every story finds the audience that can use it to drive change. <JumpChapterLink chapter={'centre-impact'}>Read more</JumpChapterLink>
						</p>
					</li>

					<li className={'mb-6'}>
						<p>
							<strong>Strengthening our newsroom</strong> - by investing in key editorial functions and necessary logistical, legal and administrative support
							so that we can deliver on our mission. <JumpChapterLink chapter={'strenghten-our-newsroom'}>Read more</JumpChapterLink>
						</p>
					</li>

					<li className={'mb-6'}>
						<p>
							<strong>Building a more sustainable business model</strong> - by growing grant funding and developing new revenue streams so that our journalism
							remains independent and viable in the long-term. <JumpChapterLink chapter={'grow-and-diversify-income'}>Read more</JumpChapterLink>
						</p>
					</li>

					<li className={'mb-6'}>
						<p>
							<strong>Nurturing an organisational culture of excitement, innovation and well-being</strong> - by better understanding and responding to the
							needs of our staff, improving compensation and benefits, and creating more space for creativity so that staff feel valued, proud and motivated to
							do their best work. <JumpChapterLink chapter={'culture-of-excitement-and-well-being'}>Read more</JumpChapterLink>
						</p>
					</li>
				</ol>
				<InlineImage
					fileName={'a_woman_cooks.jpg'}
					caption={'A woman cooks food at a displacement camp in the northern town of Ouahigouya. Nearly. Sam Mednick/TNH.'}
				/>
				<p>
					The world has changed drastically since the COVID-19 pandemic and a renewed racial justice movement worldwide. We must stay ahead of that change to
					continue to be effective and impactful in our mission to put world-class journalism at the service of the most vulnerable people in the world.
				</p>
				<p>
					<strong>
						By embracing transformative conceptions of journalism, impact, sustainability and culture, The New Humanitarian can better serve people affected by
						humanitarian crises.
					</strong>
				</p>
				<p>
					To learn more about our theory of change, please see <Link href={'https://www.thenewhumanitarian.org/s3fs_to_s3/files/styles/responsive_large/public/2024-01/TNH-ToC-graphic.jpg.webp?itok=sGoK7wbk'}>this graphic</Link>.
				</p>
				<p>As always, we welcome you to join us in this important work. If you would like to support us:</p>
				<ul>
					<li className={'mb-6'}>
						<p>
							Contact the team with your feedback and suggestions: <Link href={'mailto:hello@thenewhumanitarian.org'}>hello@thenewhumanitarian.org</Link>
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							Reach out to our external relations team to discuss institutional partnership opportunities:{' '}
							<Link href={'mailto:hello@thenewhumanitarian.org'}>hello@thenewhumanitarian.org</Link>
						</p>
					</li>
					<li className={'mb-6'}>
						<Link href={'https://www.thenewhumanitarian.org/membership'} noHover>
							<p className={'hover:underline text-burgundy'}>Become a member.</p>
						</Link>
					</li>
					<li className={'mb-6'}>
						<Link href={'https://www.thenewhumanitarian.org/content/single-donation'} noHover>
							<p className={'hover:underline text-burgundy'}>Or make a one-time donation.</p>
						</Link>
					</li>
				</ul>
				<p className={'flex justify-center items-center pt-4'}>
					<Link href={'https://www.thenewhumanitarian.org/membership'} passHref>
						<a className={'bg-burgundy text-white hover:text-white px-5 py-3 rounded-xs'}>This work is important. I’ll join you.</a>
					</Link>
				</p>
			</div>
		),
	},
	// Section 3
	{
		title: 'Decolonising our journalism',
		id: 'decolonise-journalism',
		backgroundImage: 'chapter-3-header',
		className: 'bg-gray-300',
		htmlContent: <ChapterHeader title={'Strategic priority 1: Decolonising our journalism'} quote={['We will be guided by the communities we serve.']} />,
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28 mb-32 md:mb-56'}>
				<InlineTypist text={['We will be guided by the communities we serve.']} key={`${Math.random()}-typist-3`} />
				<p>The news industry lacks diversity: in Western countries, it is heavily white male dominated and often fails to represent the people it covers.</p>
				<p>
					Standard international reporting is a legacy of an old model in which foreigners with little knowledge or experience of a country parachute in to tell
					a story in service of a foreign audience. Narratives often focus on the White/Western characters or institutions as the protagonists. The subjects of
					the story are often disempowered to shape the outcome.
				</p>
				<p>
					But the world has evolved past models that reflect white saviorism; that distinguish between ‘developed’ and ‘developing’; and that fail to recognise
					the biases at play in determining which stories get told.
				</p>
				<p>
					News organisations that think they know best what their audiences want and need disempower the very people their journalism is meant to serve. And
					newsrooms where staff all have the same world view and the same lived experiences miss important stories. If we do not represent the people we report
					on, we will fail to adequately serve them.
				</p>
				<p>
					Around the world, there is a growing demand for decolonised, meaningful participatory humanitarian and media spaces -- with different power dynamics.
				</p>
				<p>In the modern era, we cannot produce excellent journalism without decolonising it.</p>
				<p>
					Journalists are gatekeepers by definition: they use their news judgement to select stories that they believe are relevant for their audience. We seek
					to shed the negative qualities of that role while maintaining the useful aspects.
				</p>
				<InlineImage
					ineImage
					fileName={'wa-lebanon.jpg'}
					caption={
						<span>
							<Link noHover href={'https://interactive.thenewhumanitarian.org/stories/2022/07/28/whatsapp-lebanon'}>
								<a>Our interactive feature Whatsapp, Lebanon?</a>
							</Link>{' '}
							told the story of Lebanon’s collapse through the WhatsApp messages of five people who lived through it. (Rafik El Hariri/TNH)
						</span>
					}
				/>
				<p>
					The word ‘decolonisation’ can come across as violent and exclusive, but we find it a useful term because it speaks to challenging and sharing power.
					In our context, as a newsroom largely covering the so-called Global South, we see two key elements to decolonising our journalism:
				</p>
				<h3>being inclusive of, of service to, and guided by the communities we cover, meaning:</h3>
				<ul>
					<li className={'mb-6'}>
						<p>the communities we serve play a bigger role in shaping the narratives we tell</p>
					</li>
					<li className={'mb-6'}>
						<p>our stories are of service to, relevant for, and consumed by the communities we cover</p>
					</li>
					<li className={'mb-6'}>
						<p>we recognize the agency of affected people to improve their own lives</p>
					</li>
					<li className={'mb-6'}>
						<p>we tell stories of hope, not only stories of despair</p>
					</li>
					<li className={'mb-6'}>
						<p>we ask the communities we serve what decolonisation means to them</p>
					</li>
				</ul>
				<h3>decentering Western and colonial narratives and approaches in the way we produce, report and disseminate our journalism, meaning:</h3>
				<ul>
					<li className={'mb-6'}>
						<p>
							We tell stories through a local or universal - rather than predominantly Western - lens; we speak to local/global, not only Western, audiences
						</p>
					</li>
					<li className={'mb-6'}>
						<p>We acknowledge colonial histories and long-accepted power disparities, such as white supremacy, in our journalism</p>
					</li>
					<li className={'mb-6'}>
						<p>We don’t hide behind the veil of objectivity: we take a fact-based stance on issues that lead to suffering and injustice</p>
					</li>
					<li className={'mb-6'}>
						<p>Our language, framing and imagery do not depict those in the midst of crises as helpless “others”</p>
					</li>
					<li className={'mb-6'}>
						<p>We recognise non-Western forms of expression and story-telling traditions</p>
					</li>
				</ul>
				<p>Thus, in the next five years, In order to better represent the issues that matter to the communities we report about:</p>
				<ul>
					<li className={'mb-6'}>
						<p>
							<strong>Our staff will better represent the people we serve:</strong> We are proud of our efforts to build a diverse team. Our organisation and
							board of directors are both led by women of colour; our management team is entirely female; and we have always sought to work as much as possible
							with local journalists. But we are still largely a ‘Global North’ team reporting about the ‘Global South’. Bringing a greater diversity of
							perspectives and lived experiences onto our team will allow us to produce better journalism. We aim for 60 percent of our editorial team to have
							ties to the Global South by 2026.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will create more opportunities for residents of the Global South to engage with our newsroom:</strong> A year-long fellowship programme
							will support journalists from the Global South to tell stories from their communities on TNH platforms. A more structured internship programme, in
							partnership with universities from the Global South, will help build a pipeline of future talent. A dedicated position in our newsroom will
							coordinate both programs and mentor their participants, as well as identify and work with local freelance contributors. A new Op-Ed Editor will
							proactively seek out more diverse perspectives.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>Our reporters will more systematically engage with the communities they cover:</strong> Events like story circles and town halls will
							allow us - as well as policy-makers and the public - to hear directly from communities what stories they would like to tell.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will experiment with new forms of decolonised journalism:</strong> Together with the communities we cover, we will co-create products
							and formats that allow them to express themselves more authentically and become a greater part of the editorial process.{' '}
							<Link href={'https://interactive.thenewhumanitarian.org/stories/2022/07/28/whatsapp-lebanon/'}>See this piece as an example. </Link>
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will provide guidance to reporters on decolonised journalism:</strong> A public agenda for decolonised journalism and a set of
							guidelines will help our reporters know how to produce journalism in line with our vision. We will improve our training, onboarding, mentorship
							and knowledge sharing with freelancers; and we will share our learnings with the wider media sector. We will also provide training for our staff
							on diversity, equity and inclusion; unconscious bias; and white privilege.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will shift the tone, imagery and framing of our articles:</strong> We will acknowledge colonial histories and long-accepted power
							disparities, such as white supremacy, in our journalism. We will publish TNH editorials that take a stance on injustice. We will publish more
							stories that inspire hope in our readers, provide solutions, and treat those in crisis situations as more than victims.
						</p>
					</li>
				</ul>
				<p className={'flex justify-center items-center pt-4'}>
					<Link href={'https://www.thenewhumanitarian.org/membership'} passHref>
						<a className={'bg-burgundy text-white hover:text-white px-5 py-3 rounded-xs'}>This work is important. I’ll join you.</a>
					</Link>
				</p>
			</div>
		),
	},
	// Section 4
	{
		title: 'Centring impact',
		id: 'centre-impact',
		backgroundImage: 'chapter-4-header',
		className: 'bg-gray-300',
		htmlContent: <ChapterHeader title={'Strategic priority 2: Centring impact in our work'} quote={['We will connect each story to its intended audience.']} />,
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28 mb-32 md:mb-56'}>
				<InlineTypist text={['We will connect each story to its intended audience.']} key={`${Math.random()}-typist-4`} />
				<p>
					Historically, mainstream media published what they thought was important through the channels they owned - without having to distinguish which content
					was best suited for which subset of their audience.
				</p>
				<p>In a crowded online media environment, the news industry has already shifted to much more sophisticated forms of targeting.</p>
				<p>We can be transformative by tying that targeting to impact.</p>
				<p>
					The notion of impact has historically been uncomfortable for news media, because it is often confused with advocacy. But impact is not advocacy.
					Campaigners start with an answer and look for evidence to support it. Journalists start with a question, then ensure that the answer reaches those who
					can use it to create change. Our tool remains objective, fact-based journalism. But it is the means, not the end. Impact is our currency. It is
					essential for a <span className={'italic'}>mission-driven</span> newsroom that impact be central to our workflow.
				</p>
				<p>
					As the Bureau of Investigative Journalism puts it: “For anyone working in public interest journalism, impact is not a sideshow. To truly serve that
					public interest, we have to do a hell of a lot more than publish a story.”
				</p>
				<p>
					As we recognise the shifts in power in the humanitarian landscape - and seek to produce content for wider and more diverse audiences - it is
					increasingly important to map audiences that can be served by the content we publish and connect them to it.
				</p>
				<p>
					This intentionality will allow us to serve multiple audiences at once – in a more targeted way: some stories will have the greatest impact by being
					read within the local community; others will need to reach high-level policy-makers; others still will make the most difference by raising awareness
					among a wider public.
				</p>
				<InlineImage
					fileName={'section-4-inline-image.jpeg'}
					caption={`Translating stories like our reporting on dialogue between jihadists and community leaders in central Mali helps us reach audiences in the countries we report on. (Dramane Diarra/TNH)`}
				/>
				<p>In the next five years, in order to ensure that every story finds the audience that can use it to drive change:</p>
				<ul>
					<li className={'mb-6'}>
						<p>
							We will be smarter and much more intentional about who we’re trying to reach with each body of work and{' '}
							<strong>tailor our packaging and distribution accordingly</strong>. For each story we seek to publish, we will ask: Who needs to read this for it
							to have its intended impact? And if they are not current TNH readers, how do we get this story in front of them? The answer may be producing a
							Netflix series or Audible podcast, arranging briefings with decision-makers or parliamentary committees, putting on a live event with affected
							people, or packaging the content into a curriculum for students.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will strike distribution partnerships with news outlets</strong> at both the local and international level. Where relevant, we will
							also partner with local journalists in the production of our stories such that they may simultaneously produce and disseminate them in formats and
							channels that are more appropriate for local audiences.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will translate content into other languages</strong>, allowing us to reach bespoke international and local audiences.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will hire two new impact-focused staff positions:</strong> A new Director of Audience and Revenue Development will build a team
							responsible for identifying new audiences and repackaging content to reach them. An Impact Editor will help the Editorial team consider impact all
							the way through the production cycle. All things being equal, we will consider the potential impact when deciding which stories to pursue. We will
							also better track off-platform reach and more consistently communicate internally and externally about our impact.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will zero in on specific topics</strong> where we can add value any given year so as to report on them with greater consistency and
							thus impact.
						</p>
					</li>
				</ul>
				<p className={'flex justify-center items-center pt-4'}>
					<Link href={'https://www.thenewhumanitarian.org/membership'} passHref>
						<a className={'bg-burgundy text-white hover:text-white px-5 py-3 rounded-xs'}>This work is important. I’ll join you.</a>
					</Link>
				</p>
			</div>
		),
	},
	// Section 5
	{
		title: 'Strengthening our newsroom',
		id: 'strenghten-our-newsroom',
		backgroundImage: 'chapter-5-header',
		className: 'bg-gray-300',
		htmlContent: (
			<ChapterHeader
				title={'Strategic priority 3: Strengthen our newsroom'}
				quote={['We will bolster our ability to pursue the toughest stories.']}
				textShadow
			/>
		),
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28 mb-32 md:mb-56'}>
				<InlineTypist text={['We will bolster our ability to pursue the toughest stories.']} key={`${Math.random()}-typist-5`} />
				<p>
					Humanitarian crises are growing in number, frequency and severity around the world, making it harder to do justice to our mission with such a small
					team without making difficult decisions about which human suffering deserves more attention.
				</p>
				<p>
					With security risks increasing in crisis areas like Afghanistan, Haiti, Mozambique and Myanmar, planning the on-the-ground reporting that is core to
					our mission is becoming more costly, dangerous and time consuming. We do not want to resort - like many newsrooms do - to sticking to the easy
					stories, covering crises from a faraway desk.
				</p>
				<p>And as we increase our investigative reporting, so too grows the risk of legal action aimed at silencing us.</p>
				<InlineImage fileName={'annie-slemrod.jpg'} caption={`The New Humanitarian Middle East Editor Annie Slemrod reporting in Yemen. (Annie Slemrod/TNH)`} />
				<p>
					Thus, in the next five years, we will beef up our editorial team and editorial support systems in order to strengthen our ability to deliver on our
					mission and to pursue the most challenging stories.
				</p>
				<ul>
					<li className={'mb-6'}>
						<p>
							<strong>We will grow our investigations team:</strong> Investigations into sexual abuse, fraud and other misconduct by aid organisations and
							governments are among our most impactful work. Our Investigations Editor will be joined by an investigative reporter. Professional indemnity
							insurance will encourage more journalists to take on risky investigative projects for us and protect our staff, contributors and organisation.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will improve our ability to access high-risk areas:</strong> A part-time security advisor will facilitate safe travel by our reporters
							to dangerous areas, allowing us to make faster decisions to send reporters to a hotspot. A new outsourced assistance package will provide
							logistical support and management advice in a crisis situation. Consistent Hostile Environment Awareness and other training will ensure our staff
							and contributors have the knowledge to identify and mitigate risks in the course of their work.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will increase our presence on the ground and in policy hubs:</strong> We will put one journalist on retainer in each of our geographic
							coverage regions; add a roving staff correspondent to our team; hire a full-time editor on the ground in Latin America; and create a new position
							to report on policy decisions emanating from the United States.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will grow our editorial operations strategically:</strong> We will hire with an eye to avoiding bottlenecks as we generate more
							content. Outsourced IT support will allow journalists to focus on what they do best. An upgrade to our technology platform will protect the
							viability of our website in the long-term.
						</p>
					</li>
				</ul>
				<p className={'flex justify-center items-center pt-4'}>
					<Link href={'https://www.thenewhumanitarian.org/membership'} passHref>
						<a className={'bg-burgundy text-white hover:text-white px-5 py-3 rounded-xs'}>This work is important. I’ll join you.</a>
					</Link>
				</p>
			</div>
		),
	},
	// Section 6
	{
		title: 'Growing & diversifying',
		id: 'grow-and-diversify-income',
		backgroundImage: 'chapter-6-header',
		className: 'bg-gray-300',
		htmlContent: (
			<ChapterHeader title={'Strategic priority 4: Growing and diversifying our income'} quote={['We will build institutional resilience.']} textShadow />
		),
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28 mb-32 md:mb-56'}>
				<InlineTypist text={['We will build institutional resilience.']} key={`${Math.random()}-typist-6`} />
				<p>
					We are part of a movement seeking to transform the viability of international non-profit news, moving away from dependence on short-term grants
					towards more long-term and diversified forms of revenue.
				</p>
				<p>
					Over the last five years, we nearly doubled our income from $1.4 million USD in 2016 to about 2.5 million in 2021. We grew our donor base from 3 to 20
					partners and none contribute more than 20% of our overall budget. We launched a membership programme and began charging speaking fees.
				</p>
				<p>
					We aim to continue that growth by doubling our 2.5 million CHF budget over the next 5 years, and shifting from one dominant income stream to four.
				</p>
				<p>
					This will allow us to continue growing our impact, at a time when our work has never been more relevant; reduce the workload on ambitious and
					under-resourced staff; and be resilient to changes in the funding landscape.
				</p>
				<InlineImage
					fileName={'heba-chapter-6.jpeg'}
					caption={`The New Humanitarian CEO Heba Aly (second from right) at the World Economic Forum in Davos in 2022. (World Economic Forum)`}
				/>
				<ul>
					<li className={'mb-6'}>
						<p>
							<strong>We will nurture our nascent membership programme:</strong> We believe audiences are willing to pay for journalism that they trust and
							value. We will seek to grow membership revenue from 1.7% of our overall income to 5%, with target revenue of 225,000 CHF per year by 2027.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will launch an individual giving programme:</strong> We aim to reach 650,000 CHF in funding from high-net worth individuals by 2027. A
							new 501c3 charity will act as a fundraising vehicle in the US.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will maintain and increase grant funding:</strong> We will increase grant funding by at least 1.5 million CHF by reaching out to new
							donors, especially among foundations. We will seek to increase our average grant size. We will upgrade our accounting standards and undergo a more
							robust audit to build confidence with donors.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will monetize parts of our content and knowledge, while ensuring that our core products remain free for all to access:</strong> We will
							generate revenue of 225,000 CHF annually from earned revenue / paid services by 2027.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will seek out non-Western sources of funding</strong> to bring a more global set of donors to the organisation.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will develop a reserves fund</strong> equivalent to three months’ operating expenses.
						</p>
					</li>
				</ul>
				<p className={'flex justify-center items-center pt-4'}>
					<Link href={'https://www.thenewhumanitarian.org/membership'} passHref>
						<a className={'bg-burgundy text-white hover:text-white px-5 py-3 rounded-xs'}>This work is important. I’ll join you.</a>
					</Link>
				</p>
			</div>
		),
	},
	// Section 7
	{
		title: 'Well-being',
		id: 'culture-of-excitement-and-well-being',
		backgroundImage: 'chapter-7-header',
		className: 'bg-gray-300',
		htmlContent: (
			<ChapterHeader
				title={'Strategic priority 5: Nurturing an organisational culture of excitement, innovation and well-being'}
				quote={['We will support our staff to do their best work.']}
				textShadow
			/>
		),
		fullContent: (
			<div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28 mb-32 md:mb-56'}>
				<InlineTypist text={['We will support our staff to do their best work.']} />
				<p>The news industry is a tough place to work. </p>
				<p>
					It is, by definition, high-pressured and full of deadlines. The news never stops and journalists are rarely able to fully “switch off”. Especially in
					our field of humanitarian crises, reporting often involves covering traumatic incidents, which can leave journalists emotionally drained if not
					themselves traumatised. The pandemic forced many journalists to work even longer hours, in difficult personal circumstances. Many journalists are
					simply burnt out.
				</p>
				<p>
					The industry has also - for too long - tolerated toxic cultures of abuse, bullying, sexual harassment and other forms of misconduct. Competition is
					rife and most newsrooms do not have very democratised forms of decision-making.
				</p>
				<p>
					The New Humanitarian has always tried to be different. We treat each other with kindness and respect. We are open, collaborative and inclusive. And
					our staff have the ability to share their views and shape the future of the organisation. But, like everyone, we still have work to do.
				</p>
				<InlineImage fileName={'tnh-team.jpeg'} caption={`The New Humanitarian team in 2022. (TNH)`} />
				<p>
					So we will continue our journey of making work fulfilling, motivating, enjoyable and sustainable, ensuring that staff feel they work in a safe and
					positive environment; can take initiative and set stretch goals; and are supported by their managers. In particular:
				</p>
				<ul>
					<li className={'mb-6'}>
						<p>
							<strong>We will better understand the needs of our staff and tailor responses accordingly:</strong> Regular feedback surveys, via a new staff
							engagement platform, will ensure we maintain a finger on the pulse of how our staff feel and customise our responses, including manager
							development plans, based on the evidence. Staff surveys will measure key metrics of employee engagement, connection, satisfaction and well-being;
							and feed into organisational learning, planning and budgeting.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will improve benefits:</strong> We will increase our leave allowance to guard against burn-out, offer health coverage/insurance to
							staff, and provide home office support, especially to colleagues living in the Global South to ensure reliable access to internet and electricity.
							Psychological counselling will allow staff to access professional support for their mental health. Compensation will be more competitive, such
							that staff deem their monetary and non-monetary rewards to be fair and aligned with the market with a clearer path to salary progression. We will
							provide more training opportunities such that staff can continually develop.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will help staff focus on what they do best:</strong> New IT support will allow our reporters and editors to focus on producing
							world-class journalism. An HR/admin portal will provide a one-stop shop for relevant policies and administrative procedures.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will streamline processes:</strong> We will decentralise decision-making and more clearly define boundaries so that staff have greater
							autonomy over their work and so that we can more easily scale our activities. We will improve employee onboarding. And a new COO will help us
							right-size our ambitions and strike the right pace, especially when it comes to managing change.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will create more time for connection:</strong> In addition to an annual staff retreat, staff will be brought together more regularly in
							smaller groups. A new co-working space will allow London-based colleagues to connect more often. A small hospitality budget will allow us to make
							small gestures to show colleagues we care. And a new Head of People will help us be more intentional about creating connection in a dispersed
							team.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will encourage innovation:</strong> We will reward innovation and celebrate failure. Bolstering the team will provide back-stop to key
							roles that allow them to take time to work on creative innovation projects. We will continue to provide leadership coaching to our managers to
							help create environments where innovation can flourish. An organised series of trainings will allow for constant learning. We will hire for
							behavioural competencies, like agility and ability to learn, not just technical skills.
						</p>
					</li>
					<li className={'mb-6'}>
						<p>
							<strong>We will advocate and protect our values:</strong> We will socialise and reinforce our values in the way we recruit and onboard staff, and
							manage their performance. A Code of Conduct will outline expectations on behaviour and establish clear ways of reporting misconduct. As part of
							those values, we will guard against exploitative industry practices byl properly remunerating and caring for the freelancers we work with,
							including local “fixers”, translators and drivers.
						</p>
					</li>
				</ul>
				<p>Members of The New Humanitarian are helping us build the newsroom of the future. Join them by becoming a member today.</p>
				<p className={'flex justify-center items-center pt-4'}>
					<Link href={'https://www.thenewhumanitarian.org/membership'} passHref>
						<a className={'bg-burgundy text-white hover:text-white px-5 py-3 rounded-xs'}>This work is important. I’ll join you.</a>
					</Link>
				</p>
				<InlineImage
					fileName={'colombia-protest-masks.webp'}
				/>
			</div>
		),
	},
]

export default CHAPTERS
