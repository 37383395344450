import React, { useContext } from 'react'
import scrollToElement from '@components/scrollytelling/reports/scroll-to-element'
import { HamburgerIcon } from '@components/scrollytelling/reports/full-screen-header'
import StrategyContext from '@store/our-strategy/strategy-context'

const Link = ({ isActive, scrollTo, href, children }) => {
	if (scrollTo) {
		return (
			<a
				onClick={() => scrollToElement(scrollTo || 'start')}
				className={`font-bold hover:underline hover:bg-transparent ${isActive ? 'text-white underline' : ''}`}
				href={scrollTo ? null : href}
			>
				{children}
			</a>
		)
	}
	return (
		<a className={`font-bold hover:underline hover:bg-transparent ${isActive ? 'text-white underline' : ''}`} href={href}>
			{children}
		</a>
	)
}

const TopNav = () => {
	const { activeChapter, currentChapter, isOpen, setIsOpen, chapters } = useContext(StrategyContext)

	return (
		<nav className={'sticky top-15 sm:top-32 z-50'}>
			<div className={'sm:hidden w-full overflow-hidden py-5 overflow-y-hidden'} style={{ background: '#282828' }}>
				{isOpen && (
					<span onClick={() => setIsOpen(false)} className={'h-10 w-10 absolute left-4 text-white bottom-1/2 pt-4'} style={{ paddingTop: '12px' }}>
						<HamburgerIcon />
					</span>
				)}
				{isOpen ? (
					<ul className={'flex sm:hidden items-center justify-center m-0 gap-y-5 flex-col list-none overflow-x-scroll'} onClick={() => setIsOpen(false)} key={`menu-ul-is-open`}>
						{chapters.map((el, i) => {
							return (
								<li
									style={{ color: '#e6e6e6' }}
									className={'font-bold'}
									key={`top-nav-el-${el.title}`}
								>
									<Link isActive={el.id === currentChapter} scrollTo={el.id}>
										{el.title}
									</Link>
								</li>
							)
						})}
					</ul>
				) : (
					<ul className={'flex sm:hidden items-center justify-center m-0 gap-y-5 flex-col list-none overflow-x-scroll'} onClick={() => setIsOpen(true)} key={`menu-ul-is-closed`}>
						<li style={{ color: '#e6e6e6' }} className={'font-bold flex flex-row justify-center items-center'}>
							<span className={'h-10 w-10 absolute left-4 text-white top-2'} style={{ paddingTop: '2px' }}>
								<HamburgerIcon />
							</span>
							<Link isActive>{activeChapter.title}</Link>
						</li>
					</ul>
				)}
			</div>
			<ul className={'hidden sm:flex flex-row list-none gap-2 lg:gap-5 xl:gap-10 justify-center bg-white m-0 mx-auto px-3'} style={{ background: '#282828' }}>
				{chapters.map((el, i) => {
					return (
						<li
							className={
								'flex items-center justify-center transition-opacity duration-300 px-1 sm:px-0 lg:px-1 py-1 sm:py-5 lg:py-8 text-gray-50 cursor-pointer sm:text-base md:text-xl lg:text-2xl 2xl:text-3xl'
							}
							style={{ color: '#e6e6e6' }}
							key={`chapter-el-${i}`}
						>
							<Link isActive={el.id === currentChapter} scrollTo={el.id}>
								{el.title}
							</Link>
						</li>
					)
				})}
			</ul>
		</nav>
	)
}

export default TopNav
