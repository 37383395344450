import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import Typist from '@components/common/typist'

const FullScreenHeader = (props) => {
	return (
		<div
			// onClick={() => scrollToElement('start')}
			className={'relative w-full h-screen-mobile sm:h-screen-desktop h-screen-ios flex flex-col justify-center items-center text-center'}
			id={'start'}
		>
			<GatsbyImage
				image={props.image}
				alt={''}
				layout={'fill'}
				style={{
					height: '100%',
					width: '100%',
					position: 'absolute',
					top: 0,
					left: 0,
				}}
				imgStyle={{ objectFit: 'cover' }}
				className={'absolute top-0 left-0 w-full h-full'}
			/>
			<div className={'absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col flow z-50'}>
				<h1
					className={'z-50 px-3 py-2 inline-block rounded mx-auto text-white text-center text-4xl sm:text-6xl lg:text-8xl xl:text-9xl'}
					style={{
						textShadow: '3px 3px 20px #000',
					}}
				>
					<Typist texts={props.title} />
				</h1>
				<p
					className={'z-50 text-white text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl pb-8'}
					style={{
						textShadow: '3px 3px 20px #000',
					}}
				>
					{props.subtitle}
				</p>
			</div>
		</div>
	)
}

export const HamburgerIcon = () => {
	return (
		<svg fill={'currentColor'} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' className={'w-full inline fill-current'}>
			<path d='M0 2v2h24V2zm0 9v2h24v-2zm0 9v2h24v-2z'></path>
		</svg>
	)
}

export default FullScreenHeader
