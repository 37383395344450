import React, { useCallback } from 'react'

import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '@components/common/layout'
import FullScreenHeader from '@components/scrollytelling/our-strategy/full-screen-header'
import FullHeightSlide from '@components/scrollytelling/our-strategy/full-height-slide'
import TopNav from '@components/scrollytelling/our-strategy/top-navigation'
import { StrategyContextProvider } from '@store/our-strategy/strategy-context'

// Import data
import CHAPTERS from '@data/our-strategy/CHAPTERS'

const OurStrategy = (props) => {
	const Images = props.data.allFile.edges

	const getImageByName = useCallback(
		(name) => {
			return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
		},
		[Images]
	)

	return (
		<StrategyContextProvider>
			<Layout
				bgColor={'bg-white'}
				shareProps={{
					title: 'The New Humanitarian | Our strategy', // TODO
					url: 'https://interactive.thenewhumanitarian.org/reports/2022/05/31/annual-report-2021', // TODO
					socialTitle: 'The New Humanitarian | Our strategy', // TODO
					socialDescription: 'The New Humanitarian’s strategy to build better journalism.',
					socialImage: 'https://assets.thenewhumanitarian.org/s3fs-public/styles/responsive_large/public/2022-12/our-strategy-header-social.jpg.webp?itok=q_4FoDCR',
					nodeId: null,
					updatedTime: '2022-12-05T08:00:00+00:00',
					modiefiedTime: '2022-12-05T08:00:00+00:00',
					publishedTime: '2022-12-05T08:00:00+00:00',
				}}
			>
				<FullScreenHeader
					title={['Journalism from the heart of crises']}
					subtitle={'The New Humanitarian’s strategy to build better journalism'}
					image={getImageByName('header')}
				></FullScreenHeader>
				<div id='start' />
				<TopNav />
				{CHAPTERS.map((el) => {
					return (
						<FullHeightSlide chapters={CHAPTERS} className={`overflow-x-hidden ${el.className}`} chapter={el.id} key={`slide-${el.id}`}>
							{el.backgroundImage && (
								<GatsbyImage
									alt={''}
									image={getImageByName(el.backgroundImage)}
									style={{
										height: '100%',
										width: '100%',
										position: 'absolute',
										top: 0,
										left: 0,
									}}
									imgStyle={{ objectFit: 'cover' }}
									className={'z-0'}
								/>
							)}
							{el.htmlContent ? <div className={'z-0'}>{el.htmlContent}</div> : <h2 className={'text-burgundy bg-white px-5 py-3'}>{el.title}</h2>}
						</FullHeightSlide>
					)
				})}
			</Layout>
		</StrategyContextProvider>
	)
}

export default OurStrategy

export const query = graphql`
	query OurStrategy2022 {
		allFile(filter: { sourceInstanceName: { eq: "images" }, extension: { in: ["jpg", "png", "jpeg"] }, relativePath: { glob: "reports/our-strategy/*" } }) {
			edges {
				node {
					extension
					dir
					modifiedTime
					name
					relativePath
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							width: 1920
							quality: 85
							layout: FULL_WIDTH
						)
					}
				}
			}
		}
	}
`
