import React, { useEffect, useContext, useCallback, useState } from 'react'
import StorylineContext from '@store/our-strategy/strategy-context'
import { InView } from 'react-intersection-observer'
import { motion, AnimatePresence } from 'framer-motion'
import { isMobile } from 'react-device-detect'
import useKeypress from 'react-use-keypress'
import scrollToElement from '@components/scrollytelling/reports/scroll-to-element'
import ScrollableSection from '@components/scrollytelling/reports/scrollable-section'
import debounce from 'lodash.debounce'

const FullHeightSlide = ({ className, children, chapter }) => {
	const [isMobileSSR, setIsMobileSSR] = React.useState(false)

	React.useEffect(() => {
		setIsMobileSSR(isMobile)
	}, [])

	const { currentChapter, activeChapter, changeChapter } = useContext(StorylineContext)

	const [isOpen, setIsOpen] = useState(false)

	// Close when Escape is pressed
	useKeypress('Escape', () => {
		setIsOpen(false)
	})

	const debouncedchangeChapter = useCallback(debounce(changeChapter, 50), [])

	const changeChapterAndToggleIsOpen = () => {
		console.log('Is open: ' + isOpen)
		console.log('Clicked on chapter: ' + chapter)
		if (chapter !== currentChapter) {
			changeChapter(chapter)
			setTimeout(() => {
				setIsOpen(!isOpen)
			}, 100)
		} else {
			setIsOpen(!isOpen)
		}
	}

	useEffect(() => {
		const allNavItems = document.querySelectorAll('.sticky li')

		if (isOpen) {
			if (!isMobileSSR) {
				document.querySelector('body').style.overflow = 'hidden'
				document.querySelector('.sticky').style.pointerEvents = 'none'
				for (let i = 0; i < allNavItems.length; i++) {
					allNavItems[i].style.opacity = 0.2
				}
				scrollToElement(currentChapter)
			}
		} else {
			if (!isMobileSSR) {
				document.querySelector('body').style.overflow = 'visible'
				document.querySelector('.sticky').style.pointerEvents = 'auto'
				for (let i = 0; i < allNavItems.length; i++) {
					allNavItems[i].style.opacity = 1
				}
			}
		}
	}, [isOpen])

	return (
		<InView
			as='section'
			onChange={(inView) => {
				if (inView) {
					debouncedchangeChapter(chapter)
				}
			}}
			threshold={isMobileSSR ? 0.9 : 0.55}
			className={'w-full relative max-w-screen overflow-hidden'}
		>
			<AnimatePresence>
				<div
					onClick={() => changeChapterAndToggleIsOpen()}
					id={`${chapter}`}
					className={`relative cursor-pointer overflow-hidden flex flex-col justify-center items-center sm:h-screen-desktop min-h-screen/2 ${className} shadow-xl`}
					style={{
						borderBottom: isOpen ? '4px solid transparent' : '4px solid white',
					}}
				>
					{children}
					{!isMobileSSR && (
						<div>
							<motion.div
								initial={{
									x: 50,
								}}
								whileHover={{
									x: 15,
								}}
								animate={{
									opacity: isOpen ? 0 : 1,
								}}
								className={
									'hidden absolute top-0 right-0 w-48 bg-white sm:pt-screen sm:flex justify-center items-center cursor-pointer shadow-xl pointer-events-auto'
								}
								onClick={() => changeChapterAndToggleIsOpen()}
								style={{
									boxShadow: '-15px 0 15px rgba(0, 0, 0, 0.2)',
								}}
							>
								<motion.div
									className={'absolute'}
									style={{
										top: 'calc(50% - 80px - 12px)',
									}}
									initial={{
										x: -50,
									}}
									animate={{
										x: currentChapter === chapter ? '-50%' : 50,
										opacity: currentChapter === chapter ? 1 : 0,
									}}
									whileHover={{
										x: '-55%',
									}}
								>
									<motion.button
										initial={{ x: -5, scale: 1 }}
										animate={{ x: 5, scale: [1, 1, 1.025] }}
										className={'font-bold text-3xl p-5 text-white bg-burgundy shadow-xl cursor-pointer'}
										transition={{ repeat: Infinity, duration: 1, repeatType: 'reverse' }}
									>
										←Read
									</motion.button>
								</motion.div>
							</motion.div>

							<motion.div
								initial={{
									x: -50,
								}}
								whileHover={{
									x: -35,
								}}
								animate={{
									opacity: isOpen ? 1 : 0,
								}}
								className={'z-50 absolute top-0 left-0 w-48 bg-transparent pt-screen flex justify-center items-center cursor-pointer pointer-events-auto'}
								onClick={() => changeChapterAndToggleIsOpen()}
							>
								<motion.div
									className={'absolute'}
									style={{
										top: 'calc(50% - 80px - 12px)',
									}}
									initial={{
										x: 50,
									}}
									animate={{
										x: currentChapter.id === chapter ? '50%' : 50,
									}}
									whileHover={{
										x: '45%',
									}}
								>
									<button className={'font-bold text-3xl p-5 text-white bg-burgundy shadow-xl cursor-pointer z-50'}>Hide→</button>
								</motion.div>
							</motion.div>
						</div>
					)}
				</div>
				{isMobileSSR && isOpen ? (
					<div key={`mobile-fullcontent-${currentChapter}`} className={`px-5 pt-14 pb-28 sm:py-14 bg-white`}>
						{activeChapter.fullContent}
					</div>
				) : (
					<>
						<motion.div
							initial={{ x: '100%' }}
							animate={{ x: isOpen ? 80 : '100%' }}
							transition={{
								duration: 0.25,
							}}
							className={`hidden absolute top-0 left-0 px-5 pb-0 pt-12 sm:flex justify-center items-center h-screen-mobile sm:h-screen-desktop h-screen-ios bg-white w-full overflow-hidden`}
						>
							<ScrollableSection>
								{activeChapter.fullContent}
							</ScrollableSection>
						</motion.div>
					</>
				)}
			</AnimatePresence>
			<div className={`sm:hidden w-full absolute bottom-5 left-0 px-5 z-0`}>
				<div className={'w-full bg-transparent sm:pt-screen sm:flex justify-center items-center cursor-pointer shadow-xl pointer-events-auto'}>
					<button
						className={'w-full font-bold text-2xl px-5 py-3 mt-5 text-white bg-burgundy shadow-xl pointer-events-auto z-50'}
						onClick={() => changeChapterAndToggleIsOpen()}
					>
						{isOpen ? '– Hide section' : 'Read more'}
					</button>
				</div>
			</div>
		</InView>
	)
}

export default FullHeightSlide
