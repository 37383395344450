import React, { useState, createContext } from 'react'

// Import data
import CHAPTERS from '@data/our-strategy/CHAPTERS'

const StorylineContext = createContext({
	chapters: CHAPTERS,
	isOpen: false,
	setIsOpen: () => {},
	menuIsOpen: false,
	setMenuIsOpen: () => {},
	currentChapter: CHAPTERS[0].id,
	setcurrentChapter: () => {},
	activeChapter: CHAPTERS[0],
	jumpChapter: () => {},
})

export default StorylineContext

export function StrategyContextProvider({ children }) {
	const [isOpen, setIsOpen] = useState(false)
	const [menuIsOpen, setMenuIsOpen] = useState(false)
	const [currentChapter, setcurrentChapter] = useState(CHAPTERS[0].id)

	const changeChapter = (newChapter) => {
		setcurrentChapter(newChapter)
	}

	const jumpChapter = (newChapter) => {
		setTimeout(() => {
			setIsOpen(false)
		}, 0)
		setTimeout(() => {
			setcurrentChapter(newChapter)
		}, 150)
		setTimeout(() => {
			setIsOpen(true)
		}, 250)
	}

	return (
		<StorylineContext.Provider
			value={{
				isOpen,
				setIsOpen,
				menuIsOpen,
				setMenuIsOpen,
				currentChapter,
				setcurrentChapter,
				changeChapter,
				jumpChapter,
				chapters: CHAPTERS,
				activeChapter: CHAPTERS.filter((el) => el.id === currentChapter)[0],
			}}
		>
			{children}
		</StorylineContext.Provider>
	)
}
